












import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
    components:{
       
    }
})
export default class SquaredImage extends Vue {
    @Prop() image! : string;
    @Prop() small? : boolean;
    @Prop() horizontal? : boolean;
}
