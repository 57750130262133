





















import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';
import firebase from 'firebase'
import SvgIcon from '@/components/general/SvgIcon.vue';
import SquaredImage from '@/components/general/SquaredImage.vue';
@Component({
   components:{
       SvgIcon,
       SquaredImage
   }
})
export default class BookGalleryCard extends Vue {
    @Prop() item! : any;
    @Prop() imageId! : number;
    @Prop() id! : number;
    image : string = '';
    storage = firebase.storage();

     get getImage(){  
        this.storage.ref(`obras/${this.id}/gallery/${this.imageId}.png`).getDownloadURL().then((url)=>{
           this.image =  url
        })
      return this.image;
    }   

    downloadPdf(type : string, id : any) {
        var resumeRef = this.storage.ref(`otros/${id}.pdf`);
        
        resumeRef.getDownloadURL().then((url) => {
                var link = document.createElement('a');
                link.href = url;
                link.target= "_blank"
                link.dispatchEvent(new MouseEvent('click'));

        }).catch(() => {
        });
    }
}

