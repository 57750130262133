


























import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';
import Video from '@/components/general/Video.vue';
import SvgIcon from '@/components/general/SvgIcon.vue';
@Component({
    components:{
        Video,
        SvgIcon
    }
})
export default class SectionVideo extends Vue { 
   @Prop() videos! : any;
}

