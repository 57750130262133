












































import Component from 'vue-class-component';
import Vue from 'vue';
import {books} from '@/collections/books.ts';
import firebase from 'firebase';
import SvgIcon from '@/components/general/SvgIcon.vue';
import SquaredImage from '@/components/general/SquaredImage.vue';
import BookGalleryCard from '@/components/cards/BookGalleryCard.vue';
import SectionVideo from '@/components/general/SectionVideo.vue';
@Component({
    components:{
       SvgIcon,
       SquaredImage,
       BookGalleryCard,
       SectionVideo
    }
})
export default class BookDetail extends Vue {
   book: any ={};
   id : string = '';
   storage = firebase.storage();
   url = ''
   gallery : Array<string> =[]
   show = false;
   
   created(){
       this.id = this.getId();             
       this.book = books.filter((el: { id: number; }) => el.id === Number(this.id))[0]
       this.storage.ref(`obras/${this.book.id}/${this.book.id}.png`).getDownloadURL().then((url)=>{
            this.url = url
       })    
       if (this.book.gallery){
            for (var i=0;i<this.book.gallery.length;i++){
                this.storage.ref(`obras/${this.book.id}/gallery/${i+1}.png`).getDownloadURL().then((url)=>{
                    this.gallery.push(url)
                })            
            } 
        }   
   }

   getOcultedTextsLength(){
       return  this.book.description.length - this.book.show.position 
   }

    getGalleryImg(id: number){
       return this.gallery[id]
    }

    showContent(){
        this.show = true;
    }

    hideContent(){
        this.show = false;
    }

   getId(){
       return this.$route.params.id;
   }
   
   getImageUrl() : string{
       return this.url;
   }

    downloadPdf(type : string, id : any) {
        var resumeRef;
        if (type == "book"){
            resumeRef = this.storage.ref(`obras/${this.getId()}/${this.getId()}.pdf`);
        }
        else {
            resumeRef = this.storage.ref(`otros/${id}.pdf`);
        }
        resumeRef.getDownloadURL().then((url) => {
                var link = document.createElement('a');
                link.href = url;
                link.target= "_blank"
                link.dispatchEvent(new MouseEvent('click'));

        }).catch(() => {
        });
    }
}
